import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';
// import { UserTest } from '../models/user-test';

@Injectable({
    providedIn: 'root',
})
export class AuthService {
    baseUrl: string = environment.url;

    constructor(private http: HttpClient) {}

    authenticate(
    	vacancyId: string,
    	userId: string,
    	stepId: string,
    	testId: string,
    	token: string
    ): Observable<any> {
    	return this.http.get<any>(
    		`${this.baseUrl}/public/vacancy-candidate/vacancy-tests/${vacancyId}/${userId}/${stepId}/${testId}?token=${token}`
    	);
    }
}
