import { Injectable } from '@angular/core';
import { TSFixMe, AnwserComponentSkeleton, GenericObjectType } from 'src/types';

import { AddDynamicAnwsers } from './add-dynamic-anwsers';
import { DissertationComponent } from './dissertation/dissertation.component';
import { MultipleQuizComponent } from './multiple-quiz/multiple-quiz.component';
import { SimpleQuizComponent } from './simple-quiz/simple-quiz.component';
import { VideoComponent } from './video/video.component';
import { AudioComponent } from './audio/audio.component';

@Injectable({
    providedIn: 'root',
})
export class DynamicAnwsersService {
    constructor() { }

    getAnwsersComponent(
        question: TSFixMe,
        testId: string,
        cb: (param: any) => any
    ): AnwserComponentSkeleton {
        const {
            content: { questions },
        } = question;
        let [questionDefintion] = questions;
        const { alternativesType } = questionDefintion;
        questionDefintion = {
            _id: question._id,
            testId,
            ...questionDefintion,
        };

        const anwsersType: GenericObjectType = {
            multipleQuiz: new AddDynamicAnwsers(
                MultipleQuizComponent,
                questionDefintion,
                cb
            ),
            bboolean: new AddDynamicAnwsers(
                SimpleQuizComponent,
                questionDefintion,
                cb
            ),
            text: new AddDynamicAnwsers(
                DissertationComponent,
                questionDefintion,
                cb
            ),
            video: new AddDynamicAnwsers(VideoComponent, questionDefintion, cb),
            audio: new AddDynamicAnwsers(AudioComponent, questionDefintion, cb),
            // image: new AddDynamicAnwsers()
        };

        return anwsersType[question.contentFormat];
    }
}
