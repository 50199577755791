import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { BaseTest } from '../core/models/test.model';
import { StorageService } from '../core/services/storage.service';

@Component({
    selector: 'app-result-page',
    templateUrl: './result-page.component.html',
    styleUrls: ['./result-page.component.scss'],
})
export class ResultPageComponent implements OnInit, OnDestroy {
    test: BaseTest = {} as BaseTest;
    subscribeTest$: Subscription = new Subscription();

    constructor(
        private storageService: StorageService,
        private router: Router,
        private route: ActivatedRoute
    ) {}

    ngOnInit(): void {
    	this.subscribeTest$ = this.route.paramMap.subscribe((params) => {
    		const testId: string = params.get('testId') || '';
    		if (!testId) {
    			void this.router.navigate(['error', { error: 404 }]);
    		}
    		this.test = this.storageService.getTestState(testId);
    	});
    }

    ngOnDestroy(): void {
    	this.subscribeTest$.unsubscribe();
    	this.storageService.removeAll();
    }

    doSair(): void {
    	window.close();
    }
}
