import { Injectable } from '@angular/core';
import { BaseTest } from '../models/test.model';
export interface UserStorage {
    vacancyId: string;
    userId: string;
    stepId: string;
    testId: string;
    token: string;
}
@Injectable({
    providedIn: 'root',
})
export class StorageService {
    constructor() {}

    saveTestState(test: BaseTest): void {
        sessionStorage.setItem(test._id, JSON.stringify(test));
    }

    getTestState(testId: string): BaseTest {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        return JSON.parse(sessionStorage.getItem(testId) || '{}');
    }

    saveUser(userInfo: UserStorage): void {
        sessionStorage.setItem('user', JSON.stringify(userInfo));
    }

    getUser(): UserStorage {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        return JSON.parse(sessionStorage.getItem('user') || '{}');
    }

    removeAll() {
        const keys: any[] = Object.entries(sessionStorage).map(
            (item) => item[0]
        );
        for (const item of keys) {
            sessionStorage.removeItem(keys[item]);
        }
    }
}
