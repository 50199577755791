import { Component, OnInit } from '@angular/core';
// import { stat } from 'fs';
import { WatchRequestService } from '../../services/watch-request.service';

@Component({
    selector: 'app-loader',
    templateUrl: './loader.component.html',
    styleUrls: ['./loader.component.scss'],
})
export class LoaderComponent implements OnInit {
    isLoading = false;

    constructor(private watchRequestService: WatchRequestService) {
    	this.watchRequestService.isRunning.subscribe(
    		(status) => (this.isLoading = status)
    	);
    }

    ngOnInit(): void {}
}
