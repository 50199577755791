import { Injectable } from '@angular/core';
import { QuestionComponentSkeleton } from 'src/types';

import { ContentFormats } from '../models/test.model';
import { AddComponent } from './AddComponent';
import { SimpleQuestionComponent } from './simple-question/simple-question.component';
@Injectable({
    providedIn: 'root',
})
export class DynamicQuestionService {
    getQuestion(question: ContentFormats): QuestionComponentSkeleton {
        const questionDefintion = question.content?.questions[0];
        // const questionTyped: string = this.questionType(questionDefintion);

        // const templateQuestion: GenericObjectType = {
        //     compond: new AddComponent(
        //         TextImageQuestionComponent,
        //         questionDefintion
        //     ),
        //     simple: new AddComponent(
        //         SimpleQuestionComponent,
        //         questionDefintion
        //     ),
        // };

        return new AddComponent(SimpleQuestionComponent, questionDefintion);
    }
}
