import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
    HttpResponse,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { WatchRequestService } from '../services/watch-request.service';

@Injectable()
export class RequestInterceptor implements HttpInterceptor {
    private requests: HttpRequest<any>[] = [];

    constructor(private watchRequestService: WatchRequestService) {}

    removeRequest(req: HttpRequest<any>): void {
    	const id = this.requests.indexOf(req);
    	if (id >= 0) {
    		this.requests.splice(id, 1);
    	}
    	this.watchRequestService.isRunning.next(this.requests.length > 0);
    }

    intercept(
    	request: HttpRequest<unknown>,
    	next: HttpHandler
    ): Observable<HttpEvent<unknown>> {
    	this.requests.push(request);

    	this.watchRequestService.isRunning.next(true);

    	return new Observable((observer) => {
    		const sub = next.handle(request).subscribe(
    			(evt) => {
    				if (evt instanceof HttpResponse) {
    					this.removeRequest(request);
    					observer.next(evt);
    				}
    			},
    			(error) => {
    				this.removeRequest(request);
    				observer.error(error);
    			},
    			() => {
    				this.removeRequest(request);
    				observer.complete();
    			}
    		);

    		return () => {
    			this.removeRequest(request);
    			sub.unsubscribe();
    		};
    	});
    }
}
