import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
    HttpResponse,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { TestHelperService } from '../services/test-helper.service';
import { Router } from '@angular/router';

@Injectable()
export class ResponseInterceptor implements HttpInterceptor {
    constructor(
        private testHelper: TestHelperService,
        private router: Router
    ) {}

    intercept(
        request: HttpRequest<unknown>,
        next: HttpHandler
    ): Observable<HttpEvent<unknown>> {
        return new Observable((observable) => {
            const sub = next.handle(request).subscribe(
                (evt) => {
                    if (evt instanceof HttpResponse) {
                        if (evt.body) {
                            if (evt.body.userTest) {
                                this.testHelper.setInitalProgress(
                                    evt.body.userTest.progressPercentage
                                );
                            } else {
                                this.testHelper.setInitalProgress(
                                    evt.body.progressPercentage
                                );
                                if (evt.body.progressPercentage === 100) {
                                    this.router.navigateByUrl(
                                        `/test/${evt.body.testId}/completed`
                                    );
                                }
                            }
                        }
                        observable.next(evt);
                    }
                },
                (error) => {
                    observable.error(error);
                },
                () => {
                    observable.complete();
                }
            );

            return () => {
                sub.unsubscribe();
            };
        });
    }
}
