import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-bottom-btn',
    templateUrl: './bottom-btn.component.html',
    styleUrls: ['./bottom-btn.component.scss'],
})
export class BottomBtnComponent implements OnInit {
    @Input() title = 'Enviar';
    @Input() type = 'submit';
    @Input() customClass = '';
    @Input() disabled = false;

    constructor() {}

    ngOnInit(): void { }
}
