import {
    Component,
    ComponentFactoryResolver,
    Input,
    OnChanges,
    OnInit,
    ViewChild,
} from '@angular/core';
import { AddComponent, QuestionComponent } from './AddComponent';
import { QuestionsDirective } from './questions.directive';

@Component({
    selector: 'app-dynamic-questions',
    templateUrl: './dynamic-questions.component.html',
    styleUrls: ['./dynamic-questions.component.scss'],
})
export class DynamicQuestionsComponent implements OnInit, OnChanges {
    @Input() currentQuestion: AddComponent = {} as AddComponent;
    @ViewChild(QuestionsDirective, { static: true })
    questionHost: QuestionsDirective = {} as QuestionsDirective;

    constructor(private componentFactoryResolver: ComponentFactoryResolver) {}

    ngOnInit(): void {
    	this.loadQuestion();
    }

    loadQuestion(): void {
    	const componentFactory = this.componentFactoryResolver.resolveComponentFactory(
    		this.currentQuestion.component
    	);

    	const viewContainerRef = this.questionHost.viewContainerRef;
    	viewContainerRef.clear();

    	const componentRef = viewContainerRef.createComponent<QuestionComponent>(
    		componentFactory
    	);

    	componentRef.instance.content = this.currentQuestion.content;
    }

    ngOnChanges(): void {
    	this.loadQuestion();
    }
}
