import { Component, Input, OnInit } from '@angular/core';

interface Instruction {
    text?: string;
    image?: string;
    video?: string;
}

@Component({
    selector: 'app-instruction',
    templateUrl: './instruction.component.html',
    styleUrls: ['./instruction.component.scss'],
})
export class InstructionComponent implements OnInit {
    // @Input() instructions: Instruction = {};
    @Input() instructions: Record<string, string> = {};
    classSize = 'three';

    constructor() {
    }

    ngOnInit(): void {
        // Etapa: Altera as quebra de linha pela tag html
        if (this.instructions && this.instructions.text) {
            this.instructions.text = this.instructions.text?.replace(/(?:\r\n|\r|\n)/g, '<br>');
        }

        const teste = Object.keys(this.instructions).reduce((acc: any[], current: string): string[] => {
            if (current !== 'activityId' && this.instructions[current]) {
                acc.push(this.instructions[current]);
            }

            return acc;
        }, []);

        const test1 = teste.length;

        const classes: Record<number, string> = {
            1: 'one',
            2: 'two',
            3: 'three'
        };
        this.classSize = classes[test1];
    }
}
