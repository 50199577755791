import { Component, Input, OnInit } from '@angular/core';
import { StorageService } from '../../services/storage.service';
// import {} from '@angular/';

@Component({
    selector: 'app-progressbar',
    templateUrl: './progressbar.component.html',
    styleUrls: ['./progressbar.component.scss'],
})
export class ProgressbarComponent implements OnInit {
    @Input() value: string | number = 0;

    constructor() {}

    ngOnInit(): void {}
}
