import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { TSFixMe } from 'src/types';

import { environment } from '../../../environments/environment';
import { StorageService } from './storage.service';

@Injectable({
    providedIn: 'root',
})
export class TestService {
    private baseUrl: string = environment.url;

    constructor(
        private http: HttpClient,
        private storageService: StorageService
    ) {}

    patchTest(userResponse: TSFixMe): Observable<TSFixMe> {
    	const {
    		userId,
    		vacancyId,
    		token,
    		stepId,
    		testId,
    	} = this.storageService.getUser();
    	const url = `${this.baseUrl}/public/vacancy-candidate/vacancy-tests/${vacancyId}/${userId}/${stepId}/${testId}?token=${token}`;

    	return this.http.patch<any>(url, userResponse);
    }

    postFile(file: TSFixMe, activityId: string): Observable<TSFixMe> {
    	const {
    		userId,
    		vacancyId,
    		token,
    		stepId,
    		testId,
    	} = this.storageService.getUser();
    	const url = `${this.baseUrl}/public/vacancy-candidate/vacancy-tests/${vacancyId}/${userId}/${stepId}/${testId}/${activityId}/file?token=${token}`;
    	return this.http.patch<TSFixMe>(url, file);
    }
}
