import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { MatIconModule } from '@angular/material/icon';
import {
    RoundProgressModule,
    ROUND_PROGRESS_DEFAULTS,
} from 'angular-svg-round-progressbar';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { TestComponent } from './test/test.component';
import { NotFoundComponent } from './core/not-found/not-found.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ResultPageComponent } from './result-page/result-page.component';
import { SharedModule } from './core/shared/shared.module';
import { DynamicQuestionsModule } from './core/dynamic-questions/dynamic-questions.module';
import { DynamicAnwsersModule } from './core/dynamic-anwsers/dynamic-anwsers.module';
import { RequestInterceptor } from './core/interceptors/request.interceptor';
import { WatchRequestService } from './core/services/watch-request.service';
import { ResponseInterceptor } from './core/interceptors/response.interceptor';
import {
    MatDialogModule,
    MAT_DIALOG_DEFAULT_OPTIONS,
} from '@angular/material/dialog';

@NgModule({
    declarations: [
        AppComponent,
        TestComponent,
        NotFoundComponent,
        ResultPageComponent,
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        HttpClientModule,
        BrowserAnimationsModule,
        MatIconModule,
        RoundProgressModule,
        DynamicQuestionsModule,
        DynamicAnwsersModule,
        SharedModule,
        MatDialogModule,
    ],
    providers: [
        WatchRequestService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: RequestInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ResponseInterceptor,
            multi: true,
        },
        {
            provide: MAT_DIALOG_DEFAULT_OPTIONS,
            useValue: {
                hasBackdrop: true,
                disableClose: true,
                width: '80%',
            },
        },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
