import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class WatchRequestService {
    isRunning = new BehaviorSubject(false);

    constructor() {}
}
