import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './core/guards/auth.guard';
import { NotFoundComponent } from './core/not-found/not-found.component';
import { ResultPageComponent } from './result-page/result-page.component';
import { TestComponent } from './test/test.component';

const routes: Routes = [
    {
        // vacancyId/:userId/tests/:testId
        path: 'test/:vacancyId/:userId/:stepId/:testId',
        component: TestComponent,
        canActivate: [AuthGuard],
    },
    {
        path: 'test/:testId/completed',
        // path: 'test/:vacancyId/:userId/:stepId/:testId/completed',
        component: ResultPageComponent,
        // canActivate: [AuthGuard],
    },
    { path: '', redirectTo: '/test/', pathMatch: 'full' },
    { path: '**', component: NotFoundComponent },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRoutingModule {}
