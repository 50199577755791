/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import {
    Component,
    Input,
    OnInit,
    ViewChild,
    ComponentFactoryResolver,
    OnChanges,
} from '@angular/core';
import { AnwsersDirective } from './anwsers.directive';
import { AddDynamicAnwsers } from './add-dynamic-anwsers';
import { TestService } from '../services/test.service';
import { AnwserComponentSkeleton } from 'src/types';

@Component({
    selector: 'app-dynamic-anwsers',
    templateUrl: './dynamic-anwsers.component.html',
    styleUrls: ['./dynamic-anwsers.component.scss'],
})
export class DynamicAnwsersComponent implements OnInit, OnChanges {
    @Input() currentAnwsers: AddDynamicAnwsers | AnwserComponentSkeleton = {} as AddDynamicAnwsers | AnwserComponentSkeleton;
    @ViewChild(AnwsersDirective, { static: true })
    anwsersDirectiveHost: AnwsersDirective = {} as AnwsersDirective;

    constructor(private componentFactoryResolver: ComponentFactoryResolver) {}

    ngOnInit(): void {
    	this.loadResponseComponent();
    }

    loadResponseComponent(): void {
    	if (!this.currentAnwsers || !this.currentAnwsers.component) {
    		return;
    	}

    	const componentFactory = this.componentFactoryResolver.resolveComponentFactory(
    		this.currentAnwsers.component
    	);

    	const viewContainerRef = this.anwsersDirectiveHost.viewContainerRef;
    	viewContainerRef.clear();

    	const componentRef = viewContainerRef.createComponent<any>(
    		componentFactory
    	);

    	const currentAnwser: any = this.currentAnwsers;

    	// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    	componentRef.instance.questionDefintion = currentAnwser.questionDefintion;
    	// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    	componentRef.instance.fn = currentAnwser.func;
    }

    ngOnChanges(): void {
    	this.loadResponseComponent();
    }
}
