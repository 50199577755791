import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MatIconModule } from '@angular/material/icon';

import { DetailedResultComponent } from './detailed-result/detailed-result.component';
import { FloatButtonComponent } from './float-button/float-button.component';
import { ProgressbarComponent } from './progressbar/progressbar.component';
import { BottomBtnComponent } from './bottom-btn/bottom-btn.component';
import { InstructionComponent } from './instruction/instruction.component';
import { LoaderComponent } from './loader/loader.component';
import {
    RoundProgressModule,
    // RoundProgressConfig,
} from 'angular-svg-round-progressbar';
import { ModalComponent } from './modal/modal.component';
import { MatDialogModule } from '@angular/material/dialog';

@NgModule({
    declarations: [
        DetailedResultComponent,
        FloatButtonComponent,
        ProgressbarComponent,
        BottomBtnComponent,
        InstructionComponent,
        LoaderComponent,
        ModalComponent,
    ],
    imports: [
        CommonModule,
        MatIconModule,
        RoundProgressModule,
        MatDialogModule,
    ],
    exports: [
        DetailedResultComponent,
        FloatButtonComponent,
        ProgressbarComponent,
        BottomBtnComponent,
        InstructionComponent,
        LoaderComponent,
        ModalComponent,
    ],
    providers: [],
})
export class SharedModule {}
