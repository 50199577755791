import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';

import { MultipleQuizComponent } from './multiple-quiz/multiple-quiz.component';
import { SimpleQuizComponent } from './simple-quiz/simple-quiz.component';
import { AnwsersDirective } from './anwsers.directive';
import { DynamicAnwsersComponent } from './dynamic-anwsers.component';
import { VideoComponent } from './video/video.component';
import { DissertationComponent } from './dissertation/dissertation.component';
import { SharedModule } from '../shared/shared.module';
import { AudioComponent } from './audio/audio.component';

@NgModule({
    declarations: [
        DynamicAnwsersComponent,
        MultipleQuizComponent,
        SimpleQuizComponent,
        AnwsersDirective,
        VideoComponent,
        AudioComponent,
        DissertationComponent,
    ],
    imports: [CommonModule, BrowserModule, ReactiveFormsModule, SharedModule],

    exports: [DynamicAnwsersComponent],
})
export class DynamicAnwsersModule {}
