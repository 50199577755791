/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { Injectable } from '@angular/core';
import {
    CanActivate,
    ActivatedRouteSnapshot,
    // RouterStateSnapshot,
    Router,
} from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { BaseTest } from '../models/test.model';

import { AuthService } from '../services/auth.service';
import { ConnectionService } from '../services/connection.service';
import { StorageService } from '../services/storage.service';
import { TestHelperService } from '../services/test-helper.service';

@Injectable({
    providedIn: 'root',
})
export class AuthGuard implements CanActivate {
    constructor(
        private authService: AuthService,
        private router: Router,
        private storageService: StorageService,
        private testHelper: TestHelperService,
        private connectionService: ConnectionService
    ) {}

    canActivate(
        route: ActivatedRouteSnapshot
        // state: RouterStateSnapshot
    ): Observable<boolean> | boolean {
        this.connectionService.isConnect$.subscribe((value) => {
            if (!value) {
                void this.router.navigate(['error', { error: 410 }]);
            }
        });

        const { vacancyId, testId, stepId, userId } = route.params;
        const { token } = route.queryParams;

        if (!testId || !token) {
            void this.router.navigate(['error']);
        }

        return this.authService
            .authenticate(vacancyId, userId, stepId, testId, token)
            .pipe(
                catchError((error) => {
                    void this.router.navigate(['error', { error: 401 }]);
                    return throwError(error);
                }),
                map((test: BaseTest) => {
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                    test.activities = test.activities.filter(
                        (element: any) => !!element
                    );

                    test.userTest.answers = test?.userTest?.answers || [];

                    const lastActivityId =
                        test?.userTest?.answers[
                        	(test?.userTest?.answers?.length || 1) - 1
                        ]?.activityId;

                    if (lastActivityId) {
                        const question = JSON.stringify(
                            test.activities.find(
                                (t) => t._id === lastActivityId
                            )
                        );

                        const helper: string[] = test.activities.map((i) =>
                            JSON.stringify(i)
                        );
                        const idx = helper.indexOf(question);
                        this.testHelper.setInitalIndexQuestion(idx);
                    }

                    this.storageService.saveTestState(test);
                    this.storageService.saveUser({
                        vacancyId,
                        userId,
                        stepId,
                        testId,
                        token,
                    });
                    return true;
                })
            );
    }
}
