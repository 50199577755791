import {
    AfterViewInit,
    Component,
    Input,
    OnDestroy,
    OnInit,
} from '@angular/core';

import videojs, { VideoJsPlayerOptions } from 'video.js';
import * as Record from 'videojs-record';
import { TestHelperService } from '../../services/test-helper.service';
import { TestService } from '../../services/test.service';
import { TSFixMe } from 'src/types';
import { VideoResponse } from '../../models/test.model';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import * as MicrophonePlugin from 'wavesurfer.js/dist/plugin/wavesurfer.microphone';
import * as Wavesurfer from 'videojs-wavesurfer';
import { MatDialog } from '@angular/material/dialog';
import { ModalComponent } from '../../shared/modal/modal.component';
// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-member-access
Wavesurfer.microphone = MicrophonePlugin;

@Component({
    selector: 'app-audio',
    templateUrl: './audio.component.html',
    styleUrls: ['./audio.component.scss'],
})
export class AudioComponent implements OnInit, OnDestroy, AfterViewInit {
    @Input() questionDefintion: VideoResponse = {} as VideoResponse;
    @Input() fn = undefined;
    idx = 'clip1';
    config: VideoJsPlayerOptions & any;
    player: TSFixMe;
    plugin: TSFixMe;
    isVideoRecorded: boolean;

    constructor(
        private testHelper: TestHelperService,
        private testService: TestService,
        public dialog: MatDialog
    ) {
        this.isVideoRecorded = false;
        this.player = false;
        this.plugin = Record;

        this.config = {
            controls: true,
            autoplay: false,
            fluid: true,
            responsive: true,
            loop: false,
            bigPlayButton: false,
            maxLength: 60,
            controlBar: {
                volumePanel: true,
            },
            plugins: {
                wavesurfer: {
                    backend: 'WebAudio',
                    waveColor: '#36393b',
                    progressColor: 'black',
                    debug: true,
                    cursorWidth: 1,
                    displayMilliseconds: true,
                    hideScrollbar: true,
                    plugins: [
                        Wavesurfer.microphone.create({
                            bufferSize: 4096,
                            numberOfInputChannels: 1,
                            numberOfOutputChannels: 1,
                            constraints: {
                                video: false,
                                audio: true,
                            },
                        }),
                    ],
                },
                record: {
                    audio: true,
                    video: false,
                    maxLength: 60,
                    debug: true,
                },
            },
        };
    }

    ngOnInit(): void {
        this.config.maxLength = this.questionDefintion.maxDuration || 240;
        this.config.plugins.record.maxLength =
            this.questionDefintion.maxDuration || 240;
    }

    ngAfterViewInit(): void {
        const audio = 'audio_' + this.idx;

        this.player = videojs(
            document.getElementById(audio),
            this.config,
            () => {}
        );

        this.player.on('deviceReady', () => {
            this.player.record().start();
        });

        this.player.on('finishRecord', () => {
            this.isVideoRecorded = true;
        });

        
        this.player.on('stopRecord', () => {
            this.isVideoRecorded = true;
        });

        this.player.on('error', (element: any, error: any) => {
            this.isVideoRecorded = false;
            this.dialog.open(ModalComponent, {
                data: {
                    message:
                        'Infelizmente o seu vídeo foi corrompido, para gravá-lo novamente clique em "recaregar" para poder gravar novamente',
                    action: 'reload',
                },
            });
        });

        this.player.on('deviceError', () => {
            this.isVideoRecorded = false;
            this.dialog.open(ModalComponent, {
                data: {
                    message:
                        'Olá, ao que parece você está em um dispositivo sem microfone, caso a situação seja essa, tente fazer o teste em dispostivo que tenha esse item.\n Ou você não permitiu o acesso ao seu microfone, libere o acesso para continuar o teste e clique no botão abaixo para recarregar a página.',
                    action: 'reload',
                },
            });
        });
    }

    submitVideo(): void {
        const form = new FormData();
        form.append('file', this.player.recordedData);
        this.testService.postFile(form, this.questionDefintion._id).subscribe(
            (res) => this.testHelper.getNewIndexQuestion(res),
            (error) => {}
        );
    }

    handleAudio(): void {
        const confirmDialog = this.dialog.open(ModalComponent, {
            data: {
                message:
                    'Você tem certeza que deseja seguir para a próxima pergunta?',
                action: 'options',
            },
        });

        confirmDialog.afterClosed().subscribe((result) => {
            if (result) {
                this.submitVideo();
            }
        });
    }

    ngOnDestroy(): void {
        if (this.player) {
            this.player.dispose();
            this.player = false;
        }
    }
}
