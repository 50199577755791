import { Component, Input, OnInit } from '@angular/core';
import { QuestionsTypes as Question } from '../../models/test.model';
import { Title } from '@angular/platform-browser';

@Component({
    selector: 'app-simple-question',
    templateUrl: './simple-question.component.html',
    styleUrls: ['./simple-question.component.scss'],
})
export class SimpleQuestionComponent implements OnInit {
    @Input() content: Question = {} as Question;

    constructor(
        private titleService: Title
    ) { }

    ngOnInit(): void {
        // Etapa: Altera as quebra de linha pela tag html
        if (this.content && this.content.text) {
            this.content.text = this.content.text?.replace(/(?:\r\n|\r|\n)/g, '<br>');
        }
    }
}
