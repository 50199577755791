import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-simple-quiz',
    templateUrl: './simple-quiz.component.html',
    styleUrls: ['./simple-quiz.component.scss'],
})
export class SimpleQuizComponent implements OnInit {
    @Input() alternatives: any[] = [];

    constructor() {}

    ngOnInit(): void {

    }
}
