import { Injectable } from '@angular/core';
import { GenericObjectType } from 'src/types';
@Injectable({
    providedIn: 'root',
})
export class HttpStatusService {
    constructor() {}

    checkStatus({ error }: GenericObjectType): string {
        const listErrors: GenericObjectType = {
            401: 'Token inválido',
            404: 'Teste inválido',
            410: 'Sem conexão',
        };
        return listErrors[error] || 'Tente novamente';
    }
}
