import {
    AfterViewInit,
    Component,
    Input,
    OnDestroy,
    OnInit,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import videojs, { VideoJsPlayerOptions, VideoJsPlayer } from 'video.js';
import * as RecordRTC from 'recordrtc';
import * as Record from 'videojs-record';
import { TestHelperService } from '../../services/test-helper.service';
import { TestService } from '../../services/test.service';
import { TSFixMe } from 'src/types';
import { VideoResponse } from '../../models/test.model';
import { ModalComponent } from '../../shared/modal/modal.component';

@Component({
    selector: 'app-video',
    templateUrl: './video.component.html',
    styleUrls: ['./video.component.scss'],
})
export class VideoComponent implements OnInit, OnDestroy, AfterViewInit {
    @Input() questionDefintion: VideoResponse = {} as VideoResponse;
    @Input() fn = undefined;
    idx = 'clip1';
    config: VideoJsPlayerOptions & any;
    player: TSFixMe;
    plugin: TSFixMe;
    isVideoRecorded: boolean;
    VIDEO_BLOB: any;

    // @ ViewChild ('videoElm') videoElement;

    constructor(
        private testHelper: TestHelperService,
        private testService: TestService,
        public dialog: MatDialog
    ) {
        this.isVideoRecorded = false;
        this.player = false;
        this.plugin = Record;

        this.config = {
            controls: true,
            autoplay: false,
            fluid: true,
            responsive: true,
            loop: false,
            bigPlayButton: false,
            maxLength: 60,
            controlBar: {
                volumePanel: true,
            },
            plugins: {
                record: {
                    audio: true,
                    video: true,
                    maxLength: 60,
                    debug: true,
                },
            },
        };
    }

    ngOnInit(): void {
        this.config.maxLength = this.questionDefintion.maxDuration || 240;
        this.config.plugins.record.maxLength =
            this.questionDefintion.maxDuration || 240;
    }

    ngAfterViewInit(): void {
        // const video = this.videoElement.nativeElement;
        const video = 'video_' + this.idx;

        this.player = videojs(
            document.getElementById(video),
            this.config,
            () => {}
        );

        this.player.on('deviceReady', () => {
            this.player.record().start();
        });

        this.player.on('finishRecord', () => {
            // this.VIDEO_BLOB = this.player.recordedData;

            this.isVideoRecorded = true;
        });

        this.player.on('error', (element: any, error: any) => {
            this.isVideoRecorded = false;
            this.dialog.open(ModalComponent, {
                data: {
                    message:
                        'Infelizmente o seu vídeo foi corrompido, para gravá-lo novamente clique em "recaregar" para poder gravar novamente',
                    action: 'reload',
                },
            });
        });

        this.player.on('deviceError', (element: any, error: any) => {
            this.isVideoRecorded = false;
            this.dialog.open(ModalComponent, {
                data: {
                    message:
                        'Olá, ao que parece você está em um dispositivo sem microfone e/ou webcam, caso a situação seja essa, tente fazer o teste em dispostivo que tenha esses itens.\n Ou você não permitiu o acesso a sua câmera/microfone, libere o acesso para continuar o teste e clique no botão abaixo para recarregar a página.',
                    action: 'reload',
                },
            });
        });
    }

    submitVideo() {
        const form = new FormData();
        form.append('file', this.player.recordedData);
        this.testService.postFile(form, this.questionDefintion._id).subscribe(
            (res) => this.testHelper.getNewIndexQuestion(res),
            (error) => {}
        );
    }

    handleVideo(): void {
        const confirmDialog = this.dialog.open(ModalComponent, {
            data: {
                message:
                    'Você tem certeza que deseja seguir para a próxima pergunta?',
                action: 'options',
            },
        });

        confirmDialog.afterClosed().subscribe((result) => {
            if (result) {
                this.submitVideo();
            }
        });
    }

    ngOnDestroy(): void {
        if (this.player) {
            this.player.dispose();
            this.player = false;
        }
    }
}
