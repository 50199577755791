import { Component, Inject, OnInit } from '@angular/core';
import {
    MatDialog,
    MatDialogRef,
    MAT_DIALOG_DATA,
} from '@angular/material/dialog';

interface DataInterface {
    message: string;
    action: 'void' | 'reload' | 'options';
}

@Component({
    selector: 'app-modal',
    templateUrl: './modal.component.html',
    styleUrls: ['./modal.component.scss'],
})
export class ModalComponent implements OnInit {
    constructor(
        public dialogRef: MatDialogRef<ModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: DataInterface
    ) {}

    ngOnInit(): void {}

    handleClose(): void {
        this.dialogRef.close();
    }

    refresh(): void {
        window.location.reload();
    }
}
