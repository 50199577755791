import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-detailed-result',
    templateUrl: './detailed-result.component.html',
    styleUrls: ['./detailed-result.component.scss'],
})
export class DetailedResultComponent implements OnInit {
    @Input() type: 'points' | 'percent' = 'points';
    @Input() value: number = 0 as number;
    @Input() headline = '';
    @Input() icon?: string;
    suffix = '';

    constructor() {}

    ngOnInit(): void {
    	this.suffix = this.type === 'points' ? 'pts' : '%';
    	this.value = Math.round(Number(this.value));
    }
}
