import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { TSFixMe } from 'src/types';
import { TestResponse } from '../models/responseTest.model';
import { StorageService } from './storage.service';

@Injectable({
    providedIn: 'root',
})
export class TestHelperService {
    private idxQuestion = new BehaviorSubject<number>(0);
    private progress = new BehaviorSubject<number>(0);

    // eslint-disable-next-line @typescript-eslint/member-ordering
    idxQuestion$ = this.idxQuestion.asObservable();
    // eslint-disable-next-line @typescript-eslint/member-ordering
    progress$ = this.progress.asObservable();

    constructor(
        private storageService: StorageService,
        private router: Router
    ) {
    	this.progress.next(0);
    }

    getNewIndexQuestion(res: TSFixMe): void {
    	let testState = this.storageService.getTestState(res.testId);
    	testState = {
    		...testState,
    		userTest: {
    			...testState.userTest,
    			answers: [
    				// ...testState.userTest.answers,
    				...res.answers,
    			],

    			progressPercentage: res.progressPercentage,
    		},
    	};

    	this.storageService.saveTestState(testState);
    	this.idxQuestion.next(this.idxQuestion.getValue() + 1);
    }

    mountResponse(testId: string, response: TSFixMe): TestResponse {
    	const testInfo: any = this.storageService.getTestState(testId);
    	const testState = {
    		...testInfo,
    		userTest: {
    			...testInfo.userTest,
    			answers: [...testInfo.userTest.answers, response],
    		},
    	};
    	this.storageService.saveTestState(testState);
    	return {
    		testId: testInfo._id,
    		stepId: testInfo.userTest.stepId,
    		type: testInfo.type,
    		progressPercentage: this.progress.getValue(),
    		rightAnswersCount: testInfo.userTest.rightAnswersCount,
    		answers: [...testInfo.userTest.answers, response],
    	};
    }

    setInitalIndexQuestion(value: number): void {
    	return this.idxQuestion.next(value + 1);
    }

    setInitalProgress(value: number): void {
    	return this.progress.next(value);
    }
}
