import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-float-button',
    templateUrl: './float-button.component.html',
    styleUrls: ['./float-button.component.scss'],
})
export class FloatButtonComponent implements OnInit {
    @Input() title = '';
    @Input() action: any; // should be function

    constructor() {
    	// this.action();
    }

    ngOnInit(): void {}
}
