import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-text-image-question',
    templateUrl: './text-image-question.component.html',
    styleUrls: ['./text-image-question.component.scss'],
})
export class TextImageQuestionComponent implements OnInit {
    @Input() content: any;

    constructor() {
    	this.content = {
    		text:
                'Por favor, se você está visualizando essa mensagem, tire um print da tela e entre em contato',
    		alternatives: [],
    		alternativesType: '',
    		correctAnswer: '',
    		time: 0,
    	};
    }

    ngOnInit(): void {}
}
