import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DynamicQuestionsComponent } from './dynamic-questions.component';
import { SimpleQuestionComponent } from './simple-question/simple-question.component';
import { TextImageQuestionComponent } from './text-image-question/text-image-question.component';
import { QuestionsDirective } from './questions.directive';

@NgModule({
    declarations: [
        DynamicQuestionsComponent,
        SimpleQuestionComponent,
        TextImageQuestionComponent,
        QuestionsDirective,
    ],
    imports: [CommonModule, BrowserModule],

    exports: [DynamicQuestionsComponent],
})
export class DynamicQuestionsModule {}
