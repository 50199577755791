import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TextResponse } from '../../models/test.model';
import { TestHelperService } from '../../services/test-helper.service';
import { TestService } from '../../services/test.service';

@Component({
    selector: 'app-dissertation',
    templateUrl: './dissertation.component.html',
    styleUrls: ['./dissertation.component.scss'],
})
export class DissertationComponent implements OnInit {
    @Input() questionDefintion: TextResponse = {
    	max: 110,
    	min: 1,
    	text: '',
    };
    @Input() fn: (response: any) => void = () => {};
    public form: FormGroup = {} as FormGroup;

    constructor(
        private testHelper: TestHelperService,
        private testService: TestService
    ) {
    	this.form = new FormGroup({
    		answer: new FormControl(null, [
    			Validators.required,
    			Validators.minLength(3),
    		]),
    	});
    }

    ngOnInit(): void {
    	const { max, min } = this.questionDefintion;
    	this.questionDefintion.max = max || 500;
    	this.questionDefintion.min = min || 1;
    	this.form.controls.answer.setValidators([
    		Validators.required,
    		Validators.minLength(this.questionDefintion.min),
    		Validators.maxLength(this.questionDefintion.max),
    	]);

    	this.form.get('answer')?.valueChanges.subscribe((value: string) => {
    		if (
    			value.replace(/\W/g, '').length < this.questionDefintion.max &&
                value.replace(/\W/g, '').length > this.questionDefintion.min
    		) {
    			this.form.controls.answer.setErrors(null);
    		}
    	});
    }

    onSubmit(): void {
    	const { answer } = this.form.value;
    	const response = {
    		activityId: this.questionDefintion._id,
    		answer,
    		question: this.questionDefintion.text,
    		image: this.questionDefintion.image || '',
    		video: this.questionDefintion.video || '',
    	};

    	const formatedResponse = this.testHelper.mountResponse(
    		this.questionDefintion.testId,
    		response
    	);

    	this.testService.patchTest(formatedResponse).subscribe(
    		(res) => this.testHelper.getNewIndexQuestion(res),
    		(error) => { }
    	);
    }
}
