import {
    Component,
    OnChanges,
    OnDestroy,
    OnInit,
    SimpleChanges,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { StorageService, UserStorage } from '../core/services/storage.service';
import { DynamicAnwsersService } from '../core/dynamic-anwsers/dynamic-anwsers.service';
import { DynamicQuestionService } from '../core/dynamic-questions/dynamic-question.service';
import { TestService } from '../core/services/test.service';
import { BaseTest, ActivityBase } from '../core/models/test.model';
import { TestHelperService } from '../core/services/test-helper.service';
import {Title} from '@angular/platform-browser';

import {
    AnwserComponentSkeleton,
    QuestionComponentSkeleton,
    TSFixMe,
} from 'src/types';
import { TestResponse } from '../core/models/responseTest.model';

@Component({
    selector: 'app-test',
    templateUrl: './test.component.html',
    styleUrls: ['./test.component.scss'],
})
export class TestComponent implements OnInit, OnDestroy, OnChanges {
    currentValue = 0;
    idxQuestion = 0;

    test!: BaseTest;
    userInfo!: UserStorage;
    testId = '';
    btnTitle = 'Prosseguir com o teste';
    testAnwsers: ActivityBase[] = [];
    isInstructionScreen = false;
    instructions: Record<string, string> = {};
    currentQuestion: QuestionComponentSkeleton = {} as QuestionComponentSkeleton;
    currentAnwsers: AnwserComponentSkeleton = {} as AnwserComponentSkeleton;

    private subscribeTest$: Subscription = new Subscription();
    private sub: Subscription = new Subscription();
    private presentationSub$: Subscription = new Subscription();
    private subProgress$: Subscription = new Subscription();

    constructor(
        private storageService: StorageService,
        private route: ActivatedRoute,
        private router: Router,
        private dynamicAnwsersService: DynamicAnwsersService,
        private dynamicQuestionService: DynamicQuestionService,
        private testService: TestService,
        private testHelper: TestHelperService,
        private titleService: Title
    ) {}

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    ngOnChanges(changes: SimpleChanges): void {
    	throw new Error('Method not implemented.');
    }

    ngOnInit(): void {
    	this.subscribeTest$ = this.route.paramMap.subscribe((params) => {
    		this.testId = params.get('testId') || '';
    		if (!this.testId) {
    			void this.router.navigate(['error', { error: 404 }]);
    		}
    		this.test = this.storageService.getTestState(this.testId);
    		if (this.test.userTest.progressPercentage === 100) {
    			void this.router.navigateByUrl(
    				`/test/${this.test._id}/completed`
    			);
    		}
    		this.userInfo = this.storageService.getUser();
    	});
    	const { activities } = this.test;
    	this.testAnwsers = activities;

    	this.subProgress$ = this.testHelper.progress$.subscribe(
    		(value: number) => {
    			this.currentValue = value;
    		}
    	);

    	this.setNewQuestion(this.test._id);

    	this.sub = this.testHelper.idxQuestion$.subscribe((item) => {
    		this.idxQuestion = item;
    		this.setNewQuestion(this.test._id);
    	});


    }

    setNewQuestion(testId: string): void {
    	if (this.idxQuestion <= this.testAnwsers.length - 1) {
    		if (this.testAnwsers[this.idxQuestion]?.presentation) {
    			this.isInstructionScreen = true;
    			this.instructions = {
    				activityId: this.testAnwsers[this.idxQuestion]._id,
    				...this.testAnwsers[this.idxQuestion].presentation,
    			};
    		} else {
    			this.isInstructionScreen = false;
    			this.currentQuestion = this.dynamicQuestionService.getQuestion(
    				this.testAnwsers[this.idxQuestion]
    			);
    			this.currentAnwsers = this.dynamicAnwsersService.getAnwsersComponent(
    				this.testAnwsers[this.idxQuestion],
    				testId,
    				// eslint-disable-next-line @typescript-eslint/unbound-method
    				this.sendResponse
    			);

    			this.titleService.setTitle(this.testAnwsers[this.idxQuestion]?.name);
    		}
    	}
    }

    sendResponse(response: TSFixMe): any {
    	// const responseSkeleton: TestResponse = {
    	//     // testId: this.test._id,
    	// stepId: this.userInfo.stepId,
    	// type: this.test.type,
    	// answers: [response],
    	// progressPercentage: 0,
    	// };
    	// if (
    	//     this.testAnwsers &&
    	//     this.testAnwsers[this.idxQuestion] &&
    	//     this.testAnwsers[this.idxQuestion].contentFormat === 'video'
    	// ) {
    	// } else {
    	//     // this.testService.patchTest(responseSkeleton)
    	//     //     .subscribe(() => {
    	//     this.testHelper.getNewIndexQuestion();
    	//     //     }, () => { });
    	// }
    }

    nextPage(instructions: TSFixMe): void {
    	const response = this.testHelper.mountResponse(
    		this.testId,
    		instructions
    	);
    	this.presentationSub$ = this.testService.patchTest(response).subscribe(
    		(res) => {
    			this.testHelper.getNewIndexQuestion(res);
    		},
    		(error) => {
    			this.btnTitle = 'Tentar novamente';
    		}
    	);
    }

    ngOnDestroy(): void {
    	this.sub.unsubscribe();
    	this.subscribeTest$.unsubscribe();
    	this.presentationSub$.unsubscribe();
    	this.subProgress$.unsubscribe();
    }
}
