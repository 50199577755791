import { Injectable } from '@angular/core';
import { BehaviorSubject, fromEvent } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class ConnectionService {
    private isConnect = new BehaviorSubject<boolean>(true);

    isConnect$ = this.isConnect.asObservable();

    constructor() {
    	fromEvent(window, 'online').subscribe((e) => this.isConnect.next(true));
    	fromEvent(window, 'offline').subscribe((e) =>
    		this.isConnect.next(false)
    	);
    }
}
