import {
    Component,
    ElementRef,
    Input,
    OnInit,
    QueryList,
    ViewChildren,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Route } from '@angular/router';
import { BaseResponse, TestResponse } from '../../models/responseTest.model';
import { BaseTest, MultipleQuizResponse } from '../../models/test.model';
import { StorageService } from '../../services/storage.service';
import { TestHelperService } from '../../services/test-helper.service';
import { TestService } from '../../services/test.service';
@Component({
    selector: 'app-multiple-quiz',
    templateUrl: './multiple-quiz.component.html',
    styleUrls: ['./multiple-quiz.component.scss'],
})
export class MultipleQuizComponent implements OnInit {
    @Input()
    questionDefintion: MultipleQuizResponse = {} as MultipleQuizResponse;
    alternatives: any[] = [];
    selectedItem: HTMLElement = {} as HTMLElement;
    form: FormGroup;

    @ViewChildren('formRef') formRef!: QueryList<ElementRef>;

    @Input() fn: (response: BaseResponse) => void = () => {};

    constructor(
    	formBuilder: FormBuilder,
        private testService: TestService,
        private testHelper: TestHelperService,
        private storageService: StorageService
    ) {
    	this.form = formBuilder.group({
    		answer: ['', [Validators.required]],
    	});

    	this.form.valueChanges.subscribe(({ answer }) => {
    		this.updateClass('selected', answer);
    	});
    }

    ngOnInit(): void {
    	this.alternatives = this.questionDefintion.alternatives;
    }

    onSubmit(): void {
    	// this.selectedItem.classList.add(
    	//     this.selectedItem.dataset.identificador ===
    	//         this.questionDefintion.correctAnswer
    	//         ? 'isCorrect'
    	//         : 'isWrong'
    	// );
    	const { answer } = this.form.value;

    	const response = this.testHelper.mountResponse(
    		this.questionDefintion.testId,
    		{
    			isCorrect: answer === this.questionDefintion.correctAnswer,
    			answer,
    			correctAnswer: this.questionDefintion.correctAnswer,
    			question: this.questionDefintion.text,
    			activityId: this.questionDefintion._id,
    			image: this.questionDefintion.image || '',
    			video: this.questionDefintion.video || '',
    		}
    	);

    	// {
    	//     testId: this.testInfo._id,
    	//     stepId: this.testInfo.userTest.stepId,
    	//     type: this.testInfo.type,
    	//     progressPercentage: this.testInfo.userTest.progressPercentage,
    	//     rightAnswersCount: this.testInfo.userTest.rightAnswersCount,
    	//     answers: [
    	//         ...this.testInfo.userTest.answers,
    	//         {
    	//             isCorrect: answer === this.questionDefintion.correctAnswer,
    	//             answer,
    	//             correctAnswer: this.questionDefintion.correctAnswer,
    	//             question: this.questionDefintion.text,
    	//             activityId: this.questionDefintion._id,
    	//         },
    	//     ],
    	// };

    	this.testService.patchTest(response).subscribe(
    		(res) => this.testHelper.getNewIndexQuestion(res),
    		(error) => { }
    	);

    	// this.fn({
    	//     isCorrect: answer === this.questionDefintion.correctAnswer,
    	//     answer,
    	//     question: this.questionDefintion.text,
    	//     activityId: this.questionDefintion._id,
    	// });
    }

    private updateClass(className: string, compareTo: string): void {
    	this.formRef.forEach((el: any) => {
    		const labels: HTMLElement[] = el.nativeElement.children;
    		for (const label of labels) {
    			if (label.dataset.identificador === compareTo) {
    				label.classList.add(className);
    				this.selectedItem = label;
    			} else {
    				label.classList.remove(className);
    			}
    		}
    	});
    }
}
