import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpStatusService } from '../services/http-status.service';
import { StorageService } from '../services/storage.service';

@Component({
    selector: 'app-not-found',
    templateUrl: './not-found.component.html',
    styleUrls: ['./not-found.component.scss'],
})
export class NotFoundComponent implements OnInit {
    message = 'Tente novamente';
    status: number | string = 0;

    constructor(
        private route: ActivatedRoute,
        private httpStatusService: HttpStatusService,
        private router: Router,
        private storageService: StorageService
    ) { }

    ngOnInit(): void {
    	this.route.params.subscribe((params) => {
    		this.status = String(params.error);
    		this.message = this.httpStatusService.checkStatus(params);
    	});
    }

    test(): void {
    	const {
    		stepId,
    		testId,
    		token,
    		userId,
    		vacancyId,
    	} = this.storageService.getUser();

    	if (!stepId || !testId || !userId || !vacancyId || !token) {
    		return;
    	}

    	// eslint-disable-next-line @typescript-eslint/no-floating-promises
    	this.router.navigateByUrl(`test/${vacancyId}/${userId}/${stepId}/${testId}?token=${token}`);
    }
}
